import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { grey, maroon } from './colors';

export const maxWidth = 1680;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-family: acumin-pro, sans-serif;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        color: black;

        ${media.phone`
            font-size: 12px;
            line-height: 14px;
        `}
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
	}
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const content = css`
	flex: 1;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 64px;
    width: 100%;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 50px; 
    padding-right: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}


    ${media.phone`
        padding-left: 18px; 
        padding-right: 18px;
    `}
`

export const menuPadding = css`
    ${media.desktopLarge`
        padding-bottom: 50px;
    `}

    ${media.desktop`
        padding-bottom: 50px;
    `}

    ${media.tabletLandscape`
        padding-bottom: 0px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
    height: 64px;
    width: 100%;
    max-width: 320px;

    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    
    display: flex;
    justify-content: center;
    align-items: center;

    /* Animation */

    border: 2px solid black;
    transition: all 0.25s ease;
    cursor: pointer;
    
    &:hover {
        background: ${maroon};
        border: 2px solid ${maroon};
        color: white;
    }

    ${media.phone`
        font-size: 12px;
        height: 57px;
        border: 1px solid black;
        max-width: none;
    `}
`


export const pageTitle = css`
    font-family: 'learning-curve';
    font-size: 55px;
    padding: 64px 0 48px;
    align-self: center;
    font-weight: bold;

    ${media.tablet`
        font-size: 32px;
        padding: 40px 0 46px;
    `}
`
