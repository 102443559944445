import { combineReducers } from 'redux'
import localforage from 'localforage'
import { find, reject } from 'lodash';

const FETCH_CART_REQUEST = 'product/FETCH_CART_REQUEST'
const FETCH_CART_SUCCESS = 'product/FETCH_CART_SUCCESS'
const FETCH_CART_FAILURE = 'product/FETCH_CART_FAILURE'
const FETCH_CART_CACHED= 'product/FETCH_CART_CACHED'

const UPDATE_CART_REQUEST = 'product/UPDATE_CART_REQUEST'
const UPDATE_CART_SUCCESS = 'product/UPDATE_CART_SUCCESS'
const UPDATE_CART_FAILURE = 'product/UPDATE_CART_FAILURE'
const UPDATE_CART_CACHED= 'product/UPDATE_CART_CACHED'

const EMPTY_CART_REQUEST = 'checkout/EMPTY_CART_REQUEST'
const EMPTY_CART_SUCCESS = 'product/EMPTY_CART_SUCCESS'
const EMPTY_CART_FAILURE = 'product/EMPTY_CART_FAILURE'


let defaultCart = [ // This should be empty in production
    
]

const cart = (state = [], action) => {
    switch (action.type) {
        
        case FETCH_CART_SUCCESS:
            return action.response

        case UPDATE_CART_SUCCESS:
            localforage.setItem('cart', {
                lastFetched: Date.now(),
                data: action.response
            })  
            return action.response

        case EMPTY_CART_SUCCESS:
            return action.response

        default: 
            return state
    }  
}

const reducer = combineReducers({
    cart
})

export const fetchCart = () => (dispatch) => {
    localforage.getItem('cart').then(function(cart) {
        if (cart) {
            dispatch({
                type: FETCH_CART_SUCCESS,
                response: cart.data
            })
        } else {
            dispatch({
                type: FETCH_CART_SUCCESS,
                response: null
            })
        }
    })

}

export const emptyCart = () => (dispatch) => {
    dispatch({ type: EMPTY_CART_REQUEST })

    localforage.removeItem('cart')

    dispatch({
        type: EMPTY_CART_SUCCESS,
        response: null
    })
}

export const addToCart = (cartItem) => dispatch => {
    dispatch({ type: UPDATE_CART_REQUEST })

    let oldCart = [];

    // Detect if product already added and adjust existing quantity

    localforage.getItem('cart').then((cart) => {
        if (cart && cart.data) {
            oldCart = cart.data;

            let oldItem = find(oldCart, {id: cartItem.id}); 

            if (oldItem) { 
                oldItem.quantity = oldItem.quantity + cartItem.quantity;
            } else {
                oldCart.push(cartItem)
            }

            const newCart = Object.assign([], oldCart)

            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: newCart
            })            
        } else {
            const newCart = [cartItem];

            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: newCart
            })

        }
    })
    
}

export const updateQuantity = (cartItem) => dispatch => {
    
    dispatch({
        type: UPDATE_CART_REQUEST
    })

    localforage.getItem('cart').then((cart) => {
        if (cart && cart.data) {
            let oldCart = cart.data;
            let newCart;

            let oldItem = find(oldCart, {id: cartItem.id});         

            if (cartItem.quantity < 1) {
                newCart = Object.assign([], reject(oldCart, {id: cartItem.id}))
            } else {
                oldItem.quantity = cartItem.quantity;
                newCart = Object.assign([], oldCart)
            }

            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: newCart
            })

        }  else {
            dispatch({
                type: UPDATE_CART_FAILURE
            })
        }

    }) 

}

export const getCart = state => state.cart

export default reducer
