// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-venue-about-js": () => import("./../../src/templates/venue-about.js" /* webpackChunkName: "component---src-templates-venue-about-js" */),
  "component---src-templates-venue-contact-js": () => import("./../../src/templates/venue-contact.js" /* webpackChunkName: "component---src-templates-venue-contact-js" */),
  "component---src-templates-venue-functions-js": () => import("./../../src/templates/venue-functions.js" /* webpackChunkName: "component---src-templates-venue-functions-js" */),
  "component---src-templates-venue-js": () => import("./../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */),
  "component---src-templates-venue-menu-js": () => import("./../../src/templates/venue-menu.js" /* webpackChunkName: "component---src-templates-venue-menu-js" */),
  "component---src-templates-venue-post-js": () => import("./../../src/templates/venue-post.js" /* webpackChunkName: "component---src-templates-venue-post-js" */),
  "component---src-templates-venue-posts-js": () => import("./../../src/templates/venue-posts.js" /* webpackChunkName: "component---src-templates-venue-posts-js" */),
  "component---src-templates-venue-reserve-js": () => import("./../../src/templates/venue-reserve.js" /* webpackChunkName: "component---src-templates-venue-reserve-js" */)
}

