import React from "react"
import posed, { PoseGroup } from "react-pose"
import { Location } from "@reach/router"

const timeout = 250

class Transition extends React.PureComponent {
	render() {
		const { children, location } = this.props

		const RoutesContainer = posed.div({
			enter: { 
				delay: timeout, 
				delayChildren: 0 
			},
		})
	
		return (
			<Location>
				{({ location }) => {
					return (
						<PoseGroup>
							<RoutesContainer key={location && location.pathname || ''}>
								{children}
							</RoutesContainer>
						</PoseGroup>
					)
				}}
			</Location>
		)
	}
}

export default Transition