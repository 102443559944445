import { combineReducers } from 'redux'
import settings from './settings'
import user from './user'
import cart from './cart'

const reducer = combineReducers({
    settings,
    user,
    cart
})

export default reducer
